import React from 'react';
import { useNavigate } from 'react-router-dom';
import ChevronRight from '@mui/icons-material/KeyboardArrowRight';
import ChevronLeft from '@mui/icons-material/KeyboardArrowLeft';
import { useAppDispatch, useAppSelector } from 'src/store';
import { PrimaryButton, SecondaryButton } from 'src/shared/components/button/Button';
import styles from './BottomNavigation.module.scss';
import { setCurrentStep } from '../../state/habitatInsightsUploadSlice';
import { getCurrentHabitatInsightsUploadFormStep } from '../../state/habitatInsightsUploadSelector';
import useHabitatInsightsUpload from '../../hooks/useHabitatInsightsUpload';

const BottomNavigation = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const formStep = useAppSelector(getCurrentHabitatInsightsUploadFormStep);

    const { hasCompletedCurrentStep } = useHabitatInsightsUpload();
    const onStepChange = (step: number) => {
        dispatch(setCurrentStep(step));
    };

    return (
        <div className={styles.bottomNavigation}>
            <SecondaryButton onClick={() => navigate('/survey/survey-admin')}>
                <ChevronLeft /> Back
            </SecondaryButton>

            <PrimaryButton onClick={() => onStepChange(formStep + 1)} disabled={!hasCompletedCurrentStep()}>
                Next <ChevronRight />
            </PrimaryButton>
        </div>
    );
};

export default BottomNavigation;
