import { useAppDispatch, useAppSelector } from 'src/store';
import { ProgressStatus, SamplingEvent, SurveySamplingCadenceType, BasicProjectType } from 'src/shared/types';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazySurveyDesignsQuery } from 'src/app/surveys/state/api/surveyGraphSlice';
import { NewSurveyDesignFormState, setFormState } from '../state/newSurveyDesignSlice';
import { useCreateSurveyDesignMutation, useHasSurveyDesignQuery } from '../state/api/newSurveyDesignGraphSlice';
import { getCurrentSurveyDesignFormState, getCurrentSurveyDesignFormStep } from '../state/newSurveyDesignSelector';

const useSurveyDesign = () => {
    const formStep = useAppSelector(getCurrentSurveyDesignFormStep);
    const formState = useAppSelector(getCurrentSurveyDesignFormState);
    const { currentCustomerId } = useCustomer();
    const navigate = useNavigate();

    const { data: surveyDesignAvailabilityData, isFetching: isCheckingSurveyDesignPresence } = useHasSurveyDesignQuery(
        {
            projectId: formState.projectId,
        },
        {
            skip: !formState.projectId,
            refetchOnMountOrArgChange: true,
        }
    );

    const dispatch = useAppDispatch();

    const onFormChange = (fragment: Partial<NewSurveyDesignFormState>) => {
        dispatch(
            setFormState({
                ...fragment,
            })
        );
    };

    useEffect(() => {
        if (surveyDesignAvailabilityData) {
            onFormChange({
                surveyDesignCompletionStatus: surveyDesignAvailabilityData.hasSurveyDesign,
            });
        }
    }, [surveyDesignAvailabilityData, isCheckingSurveyDesignPresence]);

    const {
        projectName,
        projectId,
        projectCode,
        subscriptionType,
        surveyPublishDate,
        surveySamples,
        country,
        area,
        projectLeadRole,
        projectLeadEmail,
        projectLeadName,
        projectType,
        projectOutline,
        targetOutcomes,
        samplingCadence,
        samplingEventsPerYear,
        samplingEvents,
        sampleGroups,
        speciesOfInterest,
        habitatAssayTypes,
        surveyDesignCompletionStatus,
    } = formState;

    const { exists, metadata } = surveyDesignCompletionStatus || {};
    const isInProgressByCurrentUser =
        surveyDesignCompletionStatus && exists && metadata?.status === ProgressStatus.PENDING && metadata?.isCreatedByCurrentUser;
    const isInProgressByDifferentUser =
        surveyDesignCompletionStatus && exists && metadata?.status === ProgressStatus.PENDING && !metadata?.isCreatedByCurrentUser;
    const canCurrentUserContinueForm = surveyDesignCompletionStatus && (!exists || isInProgressByCurrentUser);

    const [createSurveyDesign] = useCreateSurveyDesignMutation();
    const [fetchSurveyDesigns] = useLazySurveyDesignsQuery();

    useEffect(() => {
        if ([!isInProgressByCurrentUser, surveyPublishDate, country, area, projectLeadRole].some(Boolean)) {
            return;
        }

        const fetchAndRestore = async () => {
            const data = await fetchSurveyDesigns({
                customerId: currentCustomerId,
                SurveyDesignStatus: ProgressStatus.PENDING,
            }).unwrap();
            const allPendingProjects = data?.listSurveyDesigns;
            const existingDefinition = allPendingProjects?.find(entry => entry.projectId === projectId);

            if (!existingDefinition) {
                return;
            }

            onFormChange({
                subscriptionType: existingDefinition.subscriptionType,
                country: existingDefinition.country,
                area: existingDefinition.area,
                projectCode: existingDefinition.projectCode,
                projectLeadRole: existingDefinition.leadRole,
                projectLeadEmail: existingDefinition.leadEmail,
                projectLeadName: existingDefinition.leadName,
                projectType: existingDefinition.projectType,
                targetOutcomes: existingDefinition.targetOutcome,
                samplingCadence: existingDefinition.samplingCadence?.type,
                samplingEventsPerYear: existingDefinition.samplingCadence?.samplingEventsPerYear,
                samplingEvents: existingDefinition.samplingCadence?.events,
                sampleGroups: existingDefinition.sampleGroups,
                speciesOfInterest: existingDefinition.speciesOfInterest,
                habitatAssayTypes: existingDefinition.habitatAssayTypes,
                projectOutline: existingDefinition.projectOutline,
                surveyPublishDate: existingDefinition.surveyPublishDate,
                surveySamples: existingDefinition.surveySamples,
            });
        };
        fetchAndRestore();
    }, [isInProgressByCurrentUser]);

    const submitSurveyDesignForm = async () => {
        if (!currentCustomerId || !projectType || !samplingCadence) {
            return;
        }

        try {
            await createSurveyDesign({
                customerId: currentCustomerId,
                projectName,
                projectId,
                projectCode,
                subscriptionType: subscriptionType,
                surveyPublishDate,
                country,
                area,
                projectLeadRole,
                projectLeadEmail,
                projectLeadName,
                projectType: projectType,
                projectOutline,
                targetOutcome: targetOutcomes,
                samplingCadenceType: samplingCadence as SurveySamplingCadenceType,
                samplingEventsPerYear,
                samplingEvent: samplingEvents,
                sampleGroups,
                speciesOfInterest,
                habitatAssayTypes,
                status: ProgressStatus.COMPLETED,
                surveySamples,
            }).unwrap();
        } catch (error) {
            console.error('rejected', error);
        }
    };

    const saveSurveyDesignForm = async () => {
        if (!currentCustomerId || !projectId) {
            return;
        }

        try {
            await createSurveyDesign({
                customerId: currentCustomerId,
                projectName,
                projectId,
                projectCode,
                subscriptionType: subscriptionType,
                surveyPublishDate,
                country,
                area,
                projectLeadRole,
                projectLeadEmail,
                projectLeadName,
                projectType: projectType || 'MAINTAIN',
                projectOutline,
                targetOutcome: targetOutcomes,
                samplingCadenceType: (samplingCadence as SurveySamplingCadenceType) || SurveySamplingCadenceType.SINGLE_YEAR,
                samplingEventsPerYear,
                samplingEvent: samplingEvents,
                sampleGroups,
                speciesOfInterest,
                habitatAssayTypes,
                status: ProgressStatus.PENDING,
                surveySamples,
            }).unwrap();

            await fetchSurveyDesigns({
                customerId: currentCustomerId,
            }).unwrap();

            navigate('/survey/survey-admin');
        } catch (error) {
            console.error('rejected', error);
        }
    };

    const hasCompletedCurrentStep = () => {
        switch (formStep) {
            case 0:
                return [projectName, subscriptionType, country, area, projectLeadRole, surveyPublishDate].every(Boolean);
            case 1:
                return projectType === BasicProjectType.GENERIC
                    ? [projectType, habitatAssayTypes.length, projectOutline].every(Boolean)
                    : [projectType, targetOutcomes, projectOutline, sampleGroups.length, habitatAssayTypes.length].every(Boolean);

            case 2:
                if (!samplingCadence || !samplingEventsPerYear) {
                    return false;
                }

                const isValidSamplingEvent = (event: SamplingEvent) => {
                    if ([event?.name, event?.fromDate, event?.toDate].every(Boolean)) {
                        const fromTimestamp = new Date(event.fromDate).getTime();
                        const toTimestamp = new Date(event.toDate).getTime();
                        return fromTimestamp < toTimestamp;
                    }
                    return false;
                };

                const validEvents = samplingEvents.map(isValidSamplingEvent);

                return Array(samplingEventsPerYear)
                    .fill('')
                    .every((_, index) => {
                        return validEvents[index];
                    });
            case 3:
                const { uploadedFileErrors } = formState;
                return [!uploadedFileErrors.length, surveySamples.length].every(Boolean);
            default:
                return true;
        }
    };

    return {
        hasCompletedCurrentStep,
        submitSurveyDesignForm,
        saveSurveyDesignForm,
        onFormChange,
        formState,
        formStep,
        isInProgressByDifferentUser,
        isDefinitionExists: exists,
        canCurrentUserContinueForm,
        isCheckingSurveyDesignPresence,
    };
};

export default useSurveyDesign;
