import React from 'react';
import moment from 'moment';

import useProject from 'src/shared/hooks/useProject';
import useFeatures from 'src/shared/hooks/useFeatures';

import { ErrorMessageWithBackground } from 'src/shared/components/error-msg/ErrorMessage';

import FishMetrics from './fish-metrics/FishMetrics';
import SpeciesRichnessMetrics from './species-richness-metrics/SpeciesRichnessMetrics';
import IucnMetrics from './iucn-metrics/IucnMetrics';
import EvolutionaryMetrics from './evolutionary-metrics/EvolutionaryMetrics';
import BacterialFunctionalDiversityMetrics from './bacterial-functional-diversity/BacterialFunctionalDiversityMetrics';
import SpeciesHighlight from './species-highlight/SpeciesHighlight';
import FungalFunctionalDiversityMetrics from './fungal-functional-diversity/FungalFunctionalDiversityMetrics';
import styles from './Metrics.module.scss';
import SingleTimepointMetricsOverview from '../single-timepoint-metrics-overview/SingleTimepointMetricsOverview';

export const Metrics = () => {
    const { currentProjectSelectedHabitatAssayType, currentProjectSelectedDateRange } = useProject();
    const {
        hasSpeciesRichnessFeature,
        hasEvolutionaryDiversityFeature,
        hasIucnRedListFeature,
        hasInvasivesFeature,
        hasFishMetricsFeature,
        hasBfdFeature,
        hasFfdFeature,
        hasSpeciesHighlightFeature,
    } = useFeatures();
    const isSingleTimepoint = currentProjectSelectedHabitatAssayType?.singleTimepoint || false;

    const speciesHighlight = hasSpeciesHighlightFeature && (
        <SpeciesHighlight isFishMetricsPresent={hasFishMetricsFeature} isSingleTimepoint={isSingleTimepoint} />
    );

    const iucnAndInvasivesMetrics = (hasIucnRedListFeature || hasInvasivesFeature) && (
        <div className={styles.iucnMetrics}>
            <IucnMetrics hideDateFilter={isSingleTimepoint} />
        </div>
    );

    const fishMetric = hasFishMetricsFeature && (
        <div className={styles.fishIndex}>
            <FishMetrics isSingleTimepoint={isSingleTimepoint} />
        </div>
    );

    const otherMetrics = (
        <div>
            {hasFishMetricsFeature ? (
                <>
                    {!isSingleTimepoint ? (
                        <div className={styles.fishMetricAndSpeciesHighlight}>
                            {fishMetric}
                            {speciesHighlight}
                        </div>
                    ) : (
                        <>
                            {fishMetric}
                            {speciesHighlight}
                        </>
                    )}
                    {iucnAndInvasivesMetrics}
                </>
            ) : (
                <>
                    {iucnAndInvasivesMetrics}
                    {speciesHighlight}
                </>
            )}
        </div>
    );

    const selectedFromDate = moment(currentProjectSelectedDateRange?.startDate);
    const selectedToDate = moment(currentProjectSelectedDateRange?.endDate);
    return (
        <div className={styles.metricsContainer}>
            {selectedToDate.isBefore(selectedFromDate) && (
                <ErrorMessageWithBackground
                    message={`Adjust the date range above so the 'Displaying from' date is earlier than the 'to' date.`}
                />
            )}
            {isSingleTimepoint && <SingleTimepointMetricsOverview />}
            {!isSingleTimepoint && (
                <div className={styles.metrics}>
                    {hasSpeciesRichnessFeature && <SpeciesRichnessMetrics />}
                    {hasEvolutionaryDiversityFeature && <EvolutionaryMetrics />}
                    {hasBfdFeature && <BacterialFunctionalDiversityMetrics />}
                    {hasFfdFeature && <FungalFunctionalDiversityMetrics />}
                </div>
            )}
            {otherMetrics}
        </div>
    );
};

export default Metrics;
