import React from 'react';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';

import { useAppSelector } from 'src/store';
import { getIsSiteMenuOpen } from 'src/global/state/site-menu/siteMenuSelector';
import classNames from 'classnames';
import { getAppRoutesAvailability } from 'src/routes/state/routesSelector';
import { useLocation, useNavigate } from 'react-router-dom';
import { appRouteSettings } from 'src/routes/AppRoutes';
import useProject from 'src/shared/hooks/useProject';
import { mapRouteSettings } from 'src/app/maps/Maps';
import styles from '../SiteMenu.module.scss';

const MapMenu = () => {
    const isOpen = useAppSelector(getIsSiteMenuOpen);
    const appRoutesAvailability = useAppSelector(getAppRoutesAvailability);

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { isHabitatInsightsProject } = useProject();

    if (!appRoutesAvailability.map) {
        return null;
    }

    const getClassNames = (menuItemPath: string) => {
        return classNames(styles.menuItem, {
            [styles.menuItemOpen]: isOpen,
            [styles.menuItemActive]: menuItemPath.startsWith(pathname),
        });
    };

    const mapPath = `/map${isHabitatInsightsProject ? mapRouteSettings.habitatInsights.path : mapRouteSettings.samples.path}`;
    return (
        <>
            <div className={getClassNames(appRouteSettings.map.path)} onClick={() => navigate(mapPath)}>
                <div className={styles.icon}>
                    <MapOutlinedIcon />
                </div>
                <div className={styles.label}>
                    {isHabitatInsightsProject ? mapRouteSettings.habitatInsights.name : mapRouteSettings.samples.name}
                </div>
            </div>
        </>
    );
};

export default MapMenu;
