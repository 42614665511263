import React from 'react';

import useFeatures from 'src/shared/hooks/useFeatures';

import styles from './SingleTimepointMetricsOverview.module.scss';
import IucnMetrics from '../metrics/iucn-metrics/IucnMetrics';
import BacterialFunctionalDiversityMetrics from '../metrics/bacterial-functional-diversity/BacterialFunctionalDiversityMetrics';
import EvolutionaryMetrics from '../metrics/evolutionary-metrics/EvolutionaryMetrics';
import SpeciesRichnessMetrics from '../metrics/species-richness-metrics/SpeciesRichnessMetrics';
import CommunityComparisonMetric from '../metrics/community-comparison-metric/CommunityComparisonMetric';
import FungalFunctionalDiversityMetrics from '../metrics/fungal-functional-diversity/FungalFunctionalDiversityMetrics';

const SingleTimepointMetricsOverview = () => {
    const {
        hasSpeciesRichnessFeature,
        hasEvolutionaryDiversityFeature,
        hasIucnRedListFeature,
        hasCommunityComparisonFeature,
        hasBfdFeature,
        hasFfdFeature,
    } = useFeatures();

    return (
        <div className={styles.singleTimepointMetricsOverview}>
            {hasSpeciesRichnessFeature && <SpeciesRichnessMetrics />}
            {hasEvolutionaryDiversityFeature && <EvolutionaryMetrics />}
            {hasIucnRedListFeature && <IucnMetrics isSingleTimepoint={true} />}
            {hasBfdFeature && <BacterialFunctionalDiversityMetrics />}
            {hasFfdFeature && <FungalFunctionalDiversityMetrics />}
            {hasCommunityComparisonFeature && <CommunityComparisonMetric />}
        </div>
    );
};

export default SingleTimepointMetricsOverview;
