import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store';
import useProject from 'src/shared/hooks/useProject';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import { useLazySurveyDesignsQuery } from 'src/app/surveys/state/api/surveyGraphSlice';
import { getCurrentHabitatInsightsUploadFormState, getCurrentHabitatInsightsUploadFormStep } from '../state/habitatInsightsUploadSelector';
import { useHabitatInsightsUploadMutation } from '../state/api/habitatInsightsUploadGraphSlice';
import { HabitatInsightsUploadFormState, setFormState } from '../state/habitatInsightsUploadSlice';

const useSurveyDesign = () => {
    const formStep = useAppSelector(getCurrentHabitatInsightsUploadFormStep);
    const formState = useAppSelector(getCurrentHabitatInsightsUploadFormState);
    const { currentProjectDetails } = useProject();
    const { currentCustomerId } = useCustomer();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onFormChange = (fragment: Partial<HabitatInsightsUploadFormState>) => {
        dispatch(
            setFormState({
                ...fragment,
            })
        );
    };

    const { sites, uploadedFileContent } = formState;

    const [habitatInsightsUpload] = useHabitatInsightsUploadMutation();
    const [fetchSurveys] = useLazySurveyDesignsQuery();

    const submitHabitatInsightsUploadForm = async () => {
        try {
            await habitatInsightsUpload({
                projectId: currentProjectDetails?.projectId || '',
                customerId: currentCustomerId,
                sites,
            }).unwrap();
            await fetchSurveys({
                customerId: currentCustomerId,
            }).unwrap();
            navigate('/survey/survey-admin');
        } catch (error) {
            console.error('rejected', error);
        }
    };

    const hasCompletedCurrentStep = () => {
        switch (formStep) {
            case 0:
                const { uploadedFileErrors } = formState;
                return [!uploadedFileErrors.length, uploadedFileContent].every(Boolean);
            default:
                return true;
        }
    };

    return {
        hasCompletedCurrentStep,
        submitHabitatInsightsUploadForm,
        onFormChange,
        formState,
        formStep,
    };
};

export default useSurveyDesign;
